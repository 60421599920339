import { doc, isBrowser } from '../utils';

type MouseListeners = {
  mousemove: (e: MouseEvent) => void;
  mouseup: (e: MouseEvent) => void;
  mousedown: (e: MouseEvent) => void;
  mouseleave: (e: MouseEvent) => void;
};

function sortListeners(f1, f2) {
  return f2.__priority - f1.__priority;
}

class MouseManager {
  pageX: number = 0;
  pageY: number = 0;
  pageDownX: number = 0;
  pageDownY: number = 0;
  pageUpX: number = 0;
  pageUpY: number = 0;
  moveX: number = 0;
  moveY: number = 0;
  timestampDown: number = 0;
  timestampUp: number = 0;
  downRect = [0, 0, 0, 0];
  button: number = 0;

  shiftKey: boolean = false;
  altKey: boolean = false;
  metaKey: boolean = false;

  get dist(): number {
    const [x, y] = this.distVec;
    return Math.sqrt(x ** 2 + y ** 2);
  }

  get distVec(): [number, number] {
    return [this.pageX - this.pageDownX, this.pageY - this.pageDownY];
  }

  listeners = {
    mousemove: [],
    mouseup: [],
    mousedown: [],
    mouseleave: []
  };

  constructor() {
    if (isBrowser) {
      doc.addEventListener('mousemove', this.handleMove);
      doc.addEventListener('mousedown', this.handleDown);
      doc.addEventListener('mouseup', this.handleUp);
      doc.addEventListener('mouseleave', this.handleLeave);
    }
  }

  on<T extends keyof MouseListeners>(event: T, callback: MouseListeners[T], priority?: number) {
    const listeners: Function[] = this.listeners[event];
    (callback as any).__priority = priority || 0;
    if (listeners.indexOf(callback) === -1) {
      listeners.push(callback);
    }

    listeners.sort(sortListeners);
  }

  off<T extends keyof MouseListeners>(event: T, callback: MouseListeners[T]) {
    const listeners: Function[] = this.listeners[event];
    if (!callback) {
      listeners.length = 0;
    } else {
      const index = listeners.indexOf(callback);
      if (index !== -1) {
        listeners.splice(index, 1);
      }
    }
  }

  emit = (event: string, e: MouseEvent) => {
    const listeners = this.listeners[event];
    for (let i = 0; i < listeners.length; i++) {
      if (listeners[i](e) === false) return;
    }
  };

  isMouseDown() {
    return this.timestampDown > this.timestampUp;
  }

  isCenterDown() {
    return this.isMouseDown() && this.button === 1;
  }

  isRightDown() {
    return this.isMouseDown() && this.button === 2;
  }

  isInside<T extends Element>(e: MouseEvent, element: T): boolean {
    return Boolean(this.findParent(e.target as Element, p => p === element));
  }

  isInsideRole(e: MouseEvent, role: RegExp | string): boolean {
    // let parent: HTMLElement | null = e.target as HTMLElement;
    const reg: RegExp = typeof role === 'string' ? new RegExp('^' + role + '$', 'i') : role;
    return Boolean(
      this.findParent(e.target as HTMLElement, p => reg.test(p.getAttribute('role') || ''))
    );
  }

  private findParent<T extends Element>(
    parent: T | null,
    check: (parent: Element) => boolean
  ): Element | void {
    if (parent) {
      if (check(parent)) return parent;
      return this.findParent(parent.parentElement, check);
    }
  }

  private handleLeave = (e: MouseEvent) => {
    this.emit('mouseleave', e);
    if (this.isMouseDown()) {
      this.handleUp(e);
    }
  };

  private handleMove = (e: MouseEvent) => {
    this.moveX = e.pageX - this.pageX;
    this.moveY = e.pageY - this.pageY;
    this.pageX = e.pageX;
    this.pageY = e.pageY;
    this.altKey = e.altKey;
    this.metaKey = e.metaKey;
    this.shiftKey = e.shiftKey;
    this.emit('mousemove', e);
  };

  private handleDown = (e: MouseEvent) => {
    this.timestampDown = e.timeStamp;
    this.button = e.button;
    this.pageDownX = this.pageX = e.pageX;
    this.pageDownY = this.pageY = e.pageY;
    this.emit('mousedown', e);
  };

  private handleUp = (e: MouseEvent) => {
    this.timestampUp = e.timeStamp;
    this.pageUpX = this.pageX = e.pageX;
    this.pageUpY = this.pageY = e.pageY;
    this.emit('mouseup', e);
  };
}

export default new MouseManager();
