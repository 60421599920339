import React from 'react';
const codeIcon =
  'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"/></svg>';

const widgetPlaceHolder = ({ children }) =>
  children || (
    <div
      className="fill column v-center h-center"
      style={{ position: 'absolute', color: 'white', background: '#222' }}
    >
      <img key="img" width="40%" src={codeIcon} />
    </div>
  );

export default widgetPlaceHolder;
