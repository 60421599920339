import React, { Fragment } from 'react';
import { useModel } from '../core/hooks';
import WidgetNode from './widget-node';

interface PageProps {
  id: string;
}

export default function Page({ id }: PageProps) {
  const model = useModel(id);
  return (
    <Fragment>
      {model &&
        model.children &&
        model.children.map(key => <WidgetNode key={key} id={key} section />)}
    </Fragment>
  );
}
