import { IRegistered, IModule } from './types';
import {
  topLevelLoad,
  loadTag,
  resolveIfNotPlainOrUrl,
  loadCss
} from './utils';
import { global, removeEl } from '../utils/dom';
import { isString } from '../utils/type';

const register: { [key: string]: IModule } = ((window as any).register = {
  vev: { id: 'vev', n: (<any>global).vev, i: [] }
});

function emptyFn() {
  return {};
}

const registerRegistry: { [id: string]: IRegistered } = ((<any>(
  global
)).registerRegistry = {});

const watchers: { [id: string]: ((resolved: any) => void)[] } = {};

const resolve: { [key: string]: string } = {};
let lastRegister: IRegistered | undefined;

let baseUrl =
  typeof location !== 'undefined'
    ? location.href.split('#')[0].split('?')[0]
    : '';
const lastSepIndex = baseUrl.lastIndexOf('/');
if (lastSepIndex !== -1) baseUrl = baseUrl.slice(0, lastSepIndex + 1);

const pkgWatchers: { [pkgId: string]: string[] } = {};
// const emptyInstantiation: IRegistered = [[], () => {}];

class System {
  r = register;
  rr = registerRegistry;
  re = resolve;
  ls() {
    console.log('r', this.r);
    console.log('rr', this.rr);
  }
  get(id: string): IModule | void {
    const load = register[id] || register[this.resolve(id)];
    if (load && load.e === null && !load.E) {
      if (load.eE) return;
      // Maybe load.n ??
      return load;
    }
  }

  delete(id: string): void {
    delete registerRegistry[id];
    delete register[id];
    const load = this.get(id);
    if (load && load.t) removeEl(load.t);
  }

  register(deps: string[], func: Function): void;
  register(key: string, deps: string[], func: Function): void;
  register(
    key: string | string[],
    deps: string[] | Function,
    func?: Function
  ): void {
    if (isString(key)) {
      lastRegister = registerRegistry[key] = [
        deps as string[],
        func as Function
      ];
      this.emitWatchers(key);
    } else {
      lastRegister = [key as string[], deps as Function];
    }
  }

  resolve(id: string, parentUrl?: string): string {
    if (register[id] || registerRegistry[id]) return id;
    return resolveIfNotPlainOrUrl(id, parentUrl || baseUrl);
  }

  async instantiate(url: string): Promise<IRegistered | undefined> {
    if (registerRegistry[url]) return registerRegistry[url];
    await this.fetch(url);
    let _last = lastRegister;
    lastRegister = undefined;
    return _last || [[], emptyFn];
  }

  private emitWatchers(key: string) {
    const pkgId = key;
    if (pkgId in pkgWatchers) {
      this.import(pkgId, true).then(r => {
        pkgWatchers[pkgId]
          .filter(widgetId => widgetId in watchers)
          .forEach(widgetId => {
            for (let cb of watchers[widgetId]) {
              if (r[widgetId]) {
                cb({ default: r[widgetId] });
              }
            }
          });
      });
    } else if (key in watchers) {
      this.import(key, true).then(r => {
        for (let cb of watchers[key]) {
          cb(r);
        }
      });
    }
  }

  /**
   * key: widgetId
   * cb:
   * pkg:
   */
  watch(key: string, cb: (resolved: any) => any);
  watch(key: string, cb: (resolved: any) => any, pkg: string);
  watch(key: string, cb: (resolved: any) => any, pkg?: string) {
    const list = watchers[key] || (watchers[key] = []);
    if (list.indexOf(cb) === -1) list.push(cb);
    if (pkg) {
      const pkgList = pkgWatchers[pkg] || (pkgWatchers[pkg] = []);
      if (pkgList.indexOf(key) === -1) pkgList.push(key);
      if (registerRegistry[pkg]) {
        this.import(pkg, true).then(r => {
          cb({ default: r[key] });
        });
      }
      // cb({default: r.WidgetId}))
    } else if (registerRegistry[key]) {
      // legacy support
      this.import(key, true).then(r => {
        cb({ ...r });
      });
    }
  }

  unwatch(key: string, cb: (resolved: any) => any) {
    const list = watchers[key];
    let index: number;
    if (list && (index = list.indexOf(cb)) !== -1) list.splice(index, 1);
  }

  import(path: string, onlyIfRegistered?: boolean): Promise<any> {
    if (path === 'react') return (self as any).React;
    if (path === 'react-dom') return (self as any).ReactDOM;

    if (onlyIfRegistered && !registerRegistry[path]) return Promise.resolve();
    const load = getOrLoad(this.resolve(path));
    return (load.C || topLevelLoad(load)).then(() => {
      return load.n;
    });
  }

  fetch(url: string): Promise<void> {
    return loadTag(url);
  }

  add(map) {
    for (let key in map)
      register[key] = { id: key, n: map[key], i: [], C: Promise.resolve() };
  }
}

const system = new System();

function getOrLoad(id: string): IModule {
  if (register[id]) return register[id];
  if (/\.css$/i.test(id)) {
    return (register[id] = {
      id,
      C: Promise.resolve(),
      n: {},
      i: [],
      t: loadCss(id)
    });
  }

  let load: IModule = (register[id] = {
    id,
    // importerSetters, the setters functions registered to this dependency
    // we retain this to add more later
    i: [],
    // module namespace object
    n: {},

    // instantiate
    // I: doLoad(load),
    // link
    // L: false, // linkPromise,
    // whether it has hoisted exports
    h: false,

    // On instantiate completion we have populated:
    // dependency load records
    d: [],
    // execution function
    // set to NULL immediately after execution (or on any failure) to indicate execution has happened
    // in such a case, pC should be used, and pLo, pLi will be emptied
    e: () => {},

    // On execution we have populated:
    // the execution error if any
    eE: undefined,
    // in the case of TLA, the execution promise
    E: undefined

    // On execution, pLi, pLo, e cleared

    // Promise for top-level completion
    // C: undefined
  });

  load.I = doLoad(load);
  load.L = load.I.then(async ([deps, setters]) => {
    load.d = await loadDeps(id, deps, setters);
  });
  return load;

  // return [registration[0], declared.setters || []];
}

async function doLoad(load: IModule): Promise<[string[], any[]]> {
  const { n: ns, i: importerSetters } = load;

  const registration = await system.instantiate(load.id);
  if (!registration)
    throw new Error('Module ' + load.id + ' did not instantiate');

  function _export(name, value) {
    // note if we have hoisted exports (including reexports)
    load.h = true;
    let changed = false;
    if (name === 'default' && typeof value === 'object') name = value;

    if (typeof name !== 'object') {
      if (!(name in ns) || ns[name] !== value) {
        ns[name] = value;
        changed = true;
      }
    } else {
      for (let p in name) {
        let value = name[p];
        if (!(p in ns) || ns[p] !== value) {
          ns[p] = value;
          changed = true;
        }
      }
    }
    if (changed) {
      for (let i = 0; i < importerSetters.length; i++) importerSetters[i](ns);
    }
    return value;
  }

  const declared = registration[1](_export);

  load.e = declared.execute || function() {};

  return [registration[0], declared.setters || []];
}

async function loadDeps(id, deps: string[], setters): Promise<IModule[]> {
  return Promise.all(
    deps.map(async (dep, i) => {
      const setter = setters[i];
      const depLoad = getOrLoad(system.resolve(dep, id));
      await depLoad.I;
      if (setter) {
        depLoad.i.push(setter);
        // only run early setters when there are hoisted exports of that module
        // the timing works here as pending hoisted export calls will trigger through importerSetters
        if (depLoad.h || !depLoad.I) setter(depLoad.n);
      }
      return depLoad;
    })
  );
}

/*
 * Support for AMD loading
 */
// (<any>global).define = define;
// (<any>global).define.amd = {};

// let amdDefineDeps, amdDefineExec;

// function unsupportedRequire() {
//   throw new Error('AMD require not supported.');
// }

// const requireExportsModule = ['require', 'exports', 'module'];

// function createAMDRegister(amdDefineDeps, amdDefineExec): IRegistered {
//   const exports = {};
//   const module = { exports: exports };
//   const depModules: any[] = [];
//   const setters: any[] = [];
//   let splice = 0;
//   for (let i = 0; i < amdDefineDeps.length; i++) {
//     const id = amdDefineDeps[i];
//     const index = setters.length;
//     if (id === 'require') {
//       depModules[i] = unsupportedRequire;
//       splice++;
//     } else if (id === 'module') {
//       depModules[i] = module;
//       splice++;
//     } else if (id === 'exports') {
//       depModules[i] = exports;
//       splice++;
//     } else {
//       // needed for ie11 lack of iteration scope
//       const idx = i;
//       setters.push(function(ns) {
//         depModules[idx] = ns.default;
//       });
//     }
//     if (splice) amdDefineDeps[index] = id;
//   }
//   if (splice) amdDefineDeps.length -= splice;
//   const amdExec = amdDefineExec;
//   return [
//     amdDefineDeps,
//     function(_export) {
//       _export('default', exports);
//       return {
//         setters: setters,
//         execute: function() {
//           module.exports = amdExec.apply(exports, depModules) || module.exports;
//           if (exports !== module.exports) _export('default', module.exports);
//         }
//       };
//     }
//   ];
// }

// // export function getAmdDeclare() : IRegistered | undefined {
// //   if(!amdDefineDeps) return;
// //   const registration = createAMDRegister(amdDefineDeps, amdDefineExec);
// //   amdDefineDeps = null;
// //   return registration;
// // };

// function define(name, deps, execute) {
//   if (typeof name === 'string') {
//     if (amdDefineDeps) {
//       registerRegistry[name] = createAMDRegister(deps, execute);
//       amdDefineDeps = [];
//       amdDefineExec = emptyFn;
//       return;
//     } else {
//       registerRegistry[name] = createAMDRegister(deps, execute);
//       name = deps;
//       deps = execute;
//     }
//   }
//   // define([], function () {})
//   if (Array.isArray(name)) {
//     amdDefineDeps = name;
//     amdDefineExec = deps;
//   }
//   // define({})
//   else if (typeof name === 'object') {
//     amdDefineDeps = [];
//     amdDefineExec = function() {
//       return name;
//     };
//   }
//   // define(function () {})
//   else if (typeof name === 'function') {
//     amdDefineDeps = requireExportsModule;
//     amdDefineExec = name;
//   }

//   lastRegister = createAMDRegister(amdDefineDeps, amdDefineExec);
// }

export default system;
