import { unmountComponentAtNode } from 'react-dom';

export const isBrowser = typeof self !== 'undefined';
export const global = isBrowser ? self : ({} as Window);
export const doc = isBrowser ? document : ({} as Document);
export const root: HTMLElement = doc.body;

// Pollufil for add and remove class (IE10 does not support multiple arguments)
export function addClass(el: HTMLElement | Element, ...cls: (string | undefined | false)[]) {
  for (let cl of cls) if (cl) el.classList.add(cl);
}

export function removeClass(el: HTMLElement, ...cls: (string | undefined | false)[]) {
  for (let cl of cls) if (cl) el.classList.remove(cl);
}

export function getById(id: string, insideEl?: Element): Element | null {
  return insideEl ? insideEl.querySelector('#' + id) : doc.getElementById(id);
}

export function hasAttrs(el: Element, attrs: string | string[]): number {
  if (typeof attrs === 'string') attrs = [attrs];
  for (let i = 0; i < attrs.length; i++) if (el.hasAttribute(attrs[i])) return i;
  return -1;
}

export function removeEl(el: Element) {
  el.parentNode && el.parentNode.removeChild(el);
}

export function unmountContent(tag: Element): void {
  unmountComponentAtNode(tag);
  removeEl(tag);
}

export function findParent(tagname: string, el: HTMLElement, levels = 15): HTMLElement | null {
  let parent: HTMLElement | null = el;
  while (parent && levels--) {
    if ((parent.nodeName || parent.tagName) === tagname) {
      return parent;
    }
    parent = <HTMLElement>parent.parentNode;
  }
  return null;
}

const liveEvents = {};
export function onLiveEvent<K extends keyof HTMLElementEventMap>(
  tagname: string,
  eventName: K,
  listener: (ev: HTMLElementEventMap[K], source: HTMLElement) => any
): () => void {
  let handler: any;
  if (root) {
    handler = liveEvents[tagname + ':' + eventName] = (e: HTMLElementEventMap[K]) => {
      const target = findParent(tagname.toUpperCase(), (e.target || e.srcElement) as HTMLElement);
      if (target) listener(e, target);
    };

    root.addEventListener(eventName, handler);
  }
  // Unsubscribe function
  return () => {
    if (handler) {
      root.removeEventListener(eventName, handler);
    }
  };
}
