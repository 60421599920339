import React from "react";
import { useModel, useRoute } from "../core/hooks";
import WidgetNode from "./widget-node";
export default function Fixed({ page }: { page?: string }) {
  const route = useRoute();
  const fixed = useModel("fixed") || ({} as any);
  if ((!page && !route) || !fixed) return null;
  const { children = [], visibility = {} } = fixed;

  return (
    <>
      {children
        .filter(id => !(id in visibility) || visibility[id] === true || visibility[id][page || route.pageKey])
        .map(id => (
          <WidgetNode key={id} id={id} fixed />
        ))}
    </>
  );
}
