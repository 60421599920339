import React, { useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { MeasureProps } from 'vev';

export default function Measure({ children, onResize, ...rest }: MeasureProps) {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      if (entries[0]) onResize(entries[0].contentRect as DOMRectReadOnly);
    });

    observer.observe(ref.current as HTMLElement);
    return () => observer.disconnect();
  }, []);

  return (
    // @ts-ignore lol
    <div {...rest} ref={ref}>
      {children}
    </div>
  );
}
