import React, { Fragment } from 'react';
import Page from './page';
import Fixed from './fixed';

export default function App({ pageKey }: { pageKey: string }) {
  return (
    <Fragment>
      <div className="__p" id={pageKey}>
        <Page id={pageKey} />
      </div>
      <Fixed />
    </Fragment>
  );
}
